.contact-sec {
	padding: 200px 0px 100px 0px;
	background: var(--accent-color-1);
	overflow-x: hidden;
}
.contact-sec .contact-heading-wrapper {
	text-align: center;
}
.contact-sec .contact-info-wrapper {
	background: #f9ec6c;
	padding: 68px 48px 29px 56px;
	border-radius: 49px 0px 0px 49px;
	overflow: hidden;
	position: relative;
}
.contact-sec .contact-info-wrapper::after {
	content: url("../img/contact-bubble.png");
	position: absolute;
	right: 0px;
	bottom: -4%;
}
.contact-sec .contact-info-wrapper .contact-icon-wrapper {
	padding-top: 80px;
}
.contact-sec .contact-info-heading-wrapper p {
	font-size: 15px !important;
}
.contact-sec .contact-info-wrapper .contact-list-wrapper {
	padding-top: 53px;
	padding-bottom: 50px;
}

.contact-sec .contact-info-wrapper .contact-list-wrapper ul {
	list-style: none;
	padding: 0px;
}

.contact-sec .contact-info-wrapper .contact-list-wrapper ul li a {
	display: flex;
	align-items: center;
}

.contact-sec .contact-info-wrapper .contact-list-wrapper ul li {
	padding: 21px 0px;
	border-bottom: 1px dashed #00000087;
}

.contact-sec
	.contact-info-wrapper
	.contact-list-wrapper
	ul
	li
	a
	span.contact-icon {
	flex: 0 0 15%;
}

.contact-sec
	.contact-info-wrapper
	.contact-list-wrapper
	ul
	li
	a
	span.contact-text {
	flex: 0 0 85%;
	color: #000;
	text-decoration: none;
	font-size: 18px;
	font-weight: 600;
}
.contact-sec .contact-info-wrapper .contact-list-wrapper ul li a {
	text-decoration: none;
	padding: 0px 14px;
}
.contact-sec .contact-info-wrapper .contact-list-wrapper ul li:last-child {
	border-bottom: 0px;
}
.contact-sec .contact-info-wrapper .contact-icon-wrapper ul {
	padding: 0px;
	list-style: none;
	display: flex;
}
.contact-sec .contact-info-wrapper .contact-icon-wrapper ul li {
	margin: 0px 20px 0px 0px;
}
.contact-sec .contact-form-wrapper {
	background: #b0f0f2;
	padding: 68px 61px 29px 46px;
	border-radius: 0px 49px 49px 0px;
}
.contact-sec .contact-form-wrapper .form-group input:focus-visible {
	outline: 1px solid var(--accent-color-2);
	box-shadow: none;
}
.contact-sec .contact-form-wrapper .form-group {
	margin: 24px 0px;
}
.contact-sec .contact-form-wrapper .form-group label {
	margin-bottom: 8px;
	font-family: "Poppins";
	font-size: 17px;
	font-weight: 500;
}
.contact-sec .contact-form-wrapper .form-group input {
	padding: 11px 24px;
	height: 51px;
	background: #d7f8f9;
	border: 0;
	border-radius: 10px;
}
.contact-sec .contact-form-wrapper .form-group input::placeholder {
	font-size: 13px;
	color: #0e0e0e;
	opacity: 50%;
}
.contact-sec .contact-form-wrapper .form-group textarea {
	padding: 11px 24px;
	background: #d7f8f9;
	border: 0;
	border-radius: 10px;
}
.contact-sec .contact-form-wrapper .form-group textarea::placeholder {
	font-size: 13px;
	color: #0e0e0e;
	opacity: 50%;
}
.contact-sec .contact-form-wrapper .btn-wrapper {
	text-align: end;
}
.contact-sec .contact-form-wrapper .btn-wrapper .btn span:first-child {
	font-size: 16px;
	font-weight: 500;
}
.contact-sec .contact-heading-wrapper p {
	font-size: 15px !important;
}
.contact-sec .video-btn-wrapper {
	position: relative;
	width: 185px;
	background: #f0c55b;
	height: 185px;
	border-radius: 50%;
	outline: 1px solid #f0c55b;
}
.contact-sec .video-btn-wrapper img {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.contact-sec .video-btn-wrapper text textPath {
	font-size: 7.3px;
	color: #000;
	font-weight: 700;
	text-transform: uppercase;
}
.contact-sec .cont-wrapper {
	position: relative;
}
.contact-sec .cont-wrapper .vio-wrap {
	position: absolute;
	top: -15%;
	right: -6%;
}
/* responsive starts here */
@media (max-width: 1440px) {
	.contact-sec .contact-info-wrapper .contact-info-heading-wrapper h4 {
		font-size: 26px !important;
	}
	.contact-sec
		.contact-info-wrapper
		.contact-list-wrapper
		ul
		li
		a
		span.contact-text {
		font-size: 15px;
	}
	.contact-sec .contact-info-wrapper .contact-list-wrapper {
		padding-top: 42px;
		padding-bottom: 50px;
	}
}
/* responsive ends here */
