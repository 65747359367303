.aboutsec {
	padding-top: 250px;
	padding-bottom: 100px;
	background: var(--accent-color-1);
}
.aboutsec .about-page-content-wrapper h6.small-heading {
	display: inline-block;
	background: var(--accent-color-3);
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}
.aboutsec .about-page-content-wrapper h2 span {
	display: block;
}
.aboutsec .banner-social-links-wrapper {
	top: 57%;
}
/* about koze sec starts here */
.about-koze-shake {
	padding: 100px 0px;
	background: #f8f8f8;
}
.about-koze-shake .about-koze-head-wrapper h6.small-heading {
	display: inline-block;
	background: var(--accent-color-3);
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}
.about-koze-shake .about-koze-head-wrapper h2 {
	font-size: 56px !important;
}
.about-koze-shake .about-franchise-wrapper {
	background: #fff;
	border-radius: 44px;
	padding: 50px 0px 0px 50px;
	overflow: hidden;
	box-shadow: -4px 12px 11px -10px rgb(255 255 255 / 42%);
}
.about-koze-shake .about-franchise-wrapper .about-franchise-img-wrapper {
	text-align: end;
	margin-top: 36px;
}
.about-koze-shake .about-location-card-wrapper {
	background: #fff;
	border-radius: 44px;
	padding: 13px 0px 16px 35px;
	overflow: hidden;
	box-shadow: -4px 12px 11px -10px rgb(255 255 255 / 42%);
	display: flex;
	align-items: center;
}
.about-koze-shake .about-location-card-wrapper .about-location-content-wrapper {
	flex: 0 0 40%;
}
.about-koze-shake
	.about-location-card-wrapper
	.about-location-content-wrapper
	h4 {
	font-size: 40px !important;
	line-height: 1.2 !important;
}
.about-koze-shake .about-franchise-wrapper .about-franchise-content-wrapper h4 {
	font-size: 40px !important;
	margin-bottom: 22px;
}
.about-koze-shake .about-Mobile-card-wrapper {
	background: #fff;
	border-radius: 44px;
	padding: 35px 0px 0px 35px;
	overflow: hidden;
	box-shadow: -4px 12px 11px -10px rgb(255 255 255 / 42%);
	display: flex;
	align-items: center;
	margin-top: 30px;
}
.about-koze-shake .about-Mobile-card-wrapper .about-Mobile-content-wrapper {
	flex: 0 0 46%;
}
.about-koze-shake
	.about-location-card-wrapper
	.about-location-content-wrapper
	h4 {
	font-size: 40px !important;
	line-height: 1.2 !important;
}

.about-koze-shake .about-franchise-wrapper .about-franchise-content-wrapper h4 {
	font-size: 40px !important;
	margin-bottom: 22px;
}

.about-koze-shake .about-Mobile-card-wrapper .about-Mobile-img-wrapper {
	flex: 0 0 53%;
}
.about-Mobile-content-wrapper a.apple-btn {
	padding: 12px 16px;
}

.about-Mobile-content-wrapper a.apple-btn img {
	width: 97px;
}

.about-Mobile-content-wrapper .buttons-wrapper {
	display: flex;
}
.about-koze-shake .about-Mobile-card-wrapper .about-Mobile-content-wrapper {
	flex: 0 0 46%;
	padding-bottom: 39px;
}
.about-koze-shake .about-Mobile-card-wrapper .about-Mobile-img-wrapper img {
	height: 289px;
	object-fit: cover;
}
/* about koze sec ends here */
/* about location detail starts here */
.about-location-detail {
	background: #f9ec6c;
	padding: 100px 0px;
}
.about-location-detail .about-loc-detail-img-wrapper {
	position: relative;
}
.about-location-detail .about-loc-detail-img-wrapper .scanner-main-wrapper {
	background: #fff;
	padding: 11px 19px 0px 19px;
	border-radius: 14px;
	width: 239px;
	border: 3px solid var(--accent-color-2);
	outline: 6px solid #fff;
	position: absolute;
	right: 0px;
	bottom: -4%;
}
.about-location-detail
	.about-loc-detail-img-wrapper
	.scanner-main-wrapper
	.scan-me-wrapper {
	display: flex;
	justify-content: space-between;
}

.about-location-detail
	.about-loc-detail-img-wrapper
	.scanner-main-wrapper
	.scan-me-wrapper
	.scanner-heading-wrapper {
	text-align: center;
}
.about-location-detail
	.about-loc-detail-img-wrapper
	.scanner-main-wrapper
	.scan-me-wrapper
	img {
	height: 89px;
	object-fit: cover;
}
.about-location-detail
	.about-loc-detail-img-wrapper
	.scanner-main-wrapper
	.scan-me-wrapper
	.scanner-heading-wrapper
	h6 {
	font-size: 17px !important;
	margin: 0px;
}
.about-location-detail .about-loc-detail-content-wrapper h6.small-heading {
	display: inline-block;
	background: var(--accent-color-2);
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}
.about-location-detail .about-loc-detail-content-wrapper {
	width: 80%;
	margin-left: 36px;
}
.about-location-detail .about-loc-detail-content-wrapper .btn {
	background: var(--accent-color-1) !important;
	color: #000 !important;
	font-weight: 600;
}
.about-location-detail .about-loc-detail-content-wrapper .btn span.btn-icon {
	background: var(--accent-color-2);
}
/* about location detail ends here */
/* about koze shake starts here */
.about-koze-shake .about-koze-shake-items-wrapper ul {
	padding: 0px;
	list-style: none;
	display: flex;
	justify-content: space-between;
}
.about-koze-shake .about-koze-shake-items-wrapper {
	width: 63%;
	margin-top: 42px;
}
.about-koze-shake .about-koze-shake-items-wrapper ul li {
	flex: 0 0 33.33%;
	text-align: center;
}
.about-koze-shake .about-koze-shake-items-wrapper ul li .about-list-wrap h6 {
	margin-top: 21px;
	font-style: italic;
	font-weight: 600 !important;
	font-size: 16px !important;
}
.about-koze-shake .about-koze-shake-wrapper h6.small-heading {
	display: inline-block;
	background: #f9ec6c;
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}
/* about koze shake ends here */
/* about koze cookies starts here */
.about-koze-cookies {
	padding: 100px 0px;
	background: var(--accent-color-2);
}
.about-koze-cookies .about-koze-cookies-items-wrapper ul {
	padding: 0px;
	list-style: none;
	display: flex;
	justify-content: space-between;
}
.about-koze-cookies .about-koze-cookies-items-wrapper {
	width: 63%;
	margin-top: 42px;
}
.about-koze-cookies .about-koze-cookies-items-wrapper ul li {
	flex: 0 0 33.33%;
	text-align: center;
}
.about-koze-cookies
	.about-koze-cookies-items-wrapper
	ul
	li
	.about-list-wrap
	h6 {
	margin-top: 21px;
	font-style: italic;
	font-weight: 600 !important;
	font-size: 16px !important;
}
.about-koze-cookies .about-koze-cookies-wrapper h6.small-heading {
	display: inline-block;
	background: #f9ec6c;
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}

/* about koze cookies ends here */
/* about koze combo starts here */
.about-koze-combo-sec {
	padding: 70px 0px;
}
.about-koze-combo-sec .about-koze-combo-img-wrappers ul {
	padding: 0px;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
}
.about-koze-combo-sec .about-koze-combo-img-wrappers ul li {
	flex: 0 0 33.33%;
}
.about-koze-combo-sec
	.about-koze-combo-img-wrappers
	ul
	li
	.koze-como-img-wrapper
	img {
	height: 133px;
	object-fit: cover;
}

.about-koze-combo-sec
	.about-koze-combo-img-wrappers
	ul
	li
	.koze-como-img-wrapper {
	text-align: center;
	margin-bottom: 37px;
}
.about-koze-combo-sec .about-koze-combo-sec-content-wrapper h6.small-heading {
	display: inline-block;
	background: #f9ec6c;
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}
.about-koze-shake .about-franchise-wrapper .about-franchise-img-wrapper img {
	height: 400px;
}
/* about koze combo ends here */
/* responsive starts here */
@media (max-width: 1440px) {
	.about-koze-shake .about-koze-head-wrapper h2 {
		font-size: 48px !important;
	}
	.about-koze-shake
		.about-franchise-wrapper
		.about-franchise-content-wrapper
		h4 {
		font-size: 32px !important;
	}
	.about-koze-shake
		.about-location-card-wrapper
		.about-location-content-wrapper
		h4 {
		font-size: 32px !important;
	}
	.about-koze-combo-sec
		.about-koze-combo-img-wrappers
		ul
		li
		.koze-como-img-wrapper {
		width: 213px;
	}
}
@media (max-width: 1366px) {
	.about-koze-shake .about-franchise-wrapper .about-franchise-img-wrapper img {
		height: 379px;
	}
}
/* responsive ends here */
