/* franchise css starts here */
.franchise {
	background: var(--accent-color-1);
	padding: 200px 0px 0px 0px;
}
.franchise .franchise-content-wrapper {
	text-align: center;
}
.franchise .franchise-content-wrapper h2 {
	font-size: 57px !important;
}
.franchise .locations-wrapper {
	display: flex;
	justify-content: space-between;
	margin-top: 33px;
}
.franchise .locations-wrapper .location-btn img {
	height: 50px;
}
.franchise .locations-wrapper .location-btn {
	background: #fff;
	border-radius: 37px;
	padding: 3px 27px 3px 19px;
	box-shadow: 2px 5px 8px #91919180;
	border: 0px;
	display: flex;
	align-items: center;
}
.franchise .locations-wrapper .location-btn h6 {
	margin: 0px;
	display: flex;
}
.franchise .locations-wrapper .location-btn h6 span {
	margin-left: 5px;
}
.franchise-form {
	background: var(--accent-color-1);
	padding-bottom: 100px;
}
.franchise-form .franchise-form-heading-wrapper {
	text-align: center;
}
.franchise-form .franchise-form-heading-wrapper h6.small-heading {
	display: inline-block;
	background: #f8eb74;
	font-size: 17px !important;
	font-weight: bold !important;
	padding: 4px 20px;
	border-radius: 29px;
}
.franchise-form-wrapper {
	background: #b0f0f2;
	padding: 25px 60px 30px 45px;
	border-radius: 49px;
}
.franchise-form .franchise-form-wrapper label {
	margin-bottom: 8px;
	font-family: "Poppins";
	font-size: 15px;
	font-weight: 500;
}
.franchise-form .franchise-form-wrapper input {
	padding: 11px 24px;
	height: 51px;
	background: #d7f8f9;
	border: 0;
	border-radius: 10px;
}
.franchise-form .franchise-form-wrapper input:focus-visible {
	outline: 1px solid var(--accent-color-2);
	box-shadow: none;
}
.franchise-form .franchise-form-wrapper .form-group {
	margin: 30px 0px;
}
.franchise-form .franchise-form-wrapper .franchise-label-wrapper {
	padding: 21px 10px;
	border-top: 1px dashed #00000087;
	border-bottom: 1px dashed #00000087;
}
.franchise-form .franchise-form-wrapper .franchise-label-wrapper h5 {
	margin: 0px;
	font-size: 25px !important;
}
.franchise-form .franchise-form-wrapper .form-group .btn-wrapper {
	text-align: end;
}
.franchise-form .franchise-form-wrapper .franchise-label-wrapper:first-child {
	border-top: 0px;
}
.franchise-form .franchise-form-heading-wrapper h2 {
	font-size: 57px !important;
}
.franchise-form .franchise-form-heading-wrapper p {
	font-size: 15px !important;
}
/* franchise css ends here */
/* responsive starts here */
@media (max-width: 1440px) {
	.franchise .locations-wrapper .location-btn h6 {
		font-size: 16px !important;
	}
	.franchise .locations-wrapper .location-btn {
		padding: 3px 18px 3px 20px;
	}
	.franchise .franchise-content-wrapper h2 {
		font-size: 47px !important;
	}
}
/* responsive ends here */
